import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import style from "./categoryCard.module.sass";
import Image from "gatsby-image";

const CategoryCard = ({ data, wide = false }) => (
    <Link
        to={`/e-shop/${data.parent ? data.parent.slug + "/" : ""}${data.slug}`}
        className={classNames({
            [style.card]: true,
            [style.cardWide]: wide,
        })}
        data-test="category-gold"
    >
        <div className={style.image}>
            <Image alt={data.name} fixed={data.image.childImageSharp.fixed} />
            <Image
                alt={data.name}
                fixed={data.image.childImageSharp.mobileFixed}
            />
        </div>
        <div className={style.content}>
            <h3>{data.name}</h3>
            <p className={style.description}>{wide && data.description}</p>
            <p className={style.link}>
                <span>více</span>
                <img
                    src={require("../../images/icons_new/more.svg")}
                    alt="více"
                />
            </p>
        </div>
    </Link>
);

export default CategoryCard;
