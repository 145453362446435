import React from "react";
import style from "./sadSpendingBox.module.sass";
import Button from "../Button";
import Img from "gatsby-image";

const SadSpendingBox = ({ images, state }) => (
    <section className={style.wrapper}>
        <div className={style.spenderImage}>
            <Img fixed={images.spender} className={style.image} />
        </div>
        <div className={style.info}>
            <h2>odrazují vás jednorázové výdaje?</h2>
            <p>
                Ale přesto vás láká investování do vzácných kovů? <br />
                Formou pravidelného spoření můžete investovat už od 500 Kč
                měsíčně
            </p>
            <div className={style.buttons}>
                <Button
                    color="outline-gold-dark"
                    type="link"
                    uri="/vyvoj-cen"
                    state={state}
                >
                    Kalkulačka výnosů
                    <svg
                        width="19"
                        height="24"
                        viewBox="0 0 19 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="0.5"
                            y="23.5"
                            width="23"
                            height="18"
                            rx="2.5"
                            transform="rotate(-90 0.5 23.5)"
                            stroke="#AD9752"
                        />
                        <rect
                            x="3.5"
                            y="3.5"
                            width="12"
                            height="4"
                            stroke="#AD9752"
                        />
                        <line
                            x1="4"
                            y1="15.5"
                            x2="15"
                            y2="15.5"
                            stroke="#AD9752"
                        />
                        <line
                            x1="4"
                            y1="12.5"
                            x2="15"
                            y2="12.5"
                            stroke="#AD9752"
                        />
                        <path d="M6.5 21L6.5 10" stroke="#AD9752" />
                        <path d="M9.5 21L9.5 10" stroke="#AD9752" />
                        <path d="M12.5 21L12.5 10" stroke="#AD9752" />
                        <line
                            x1="4"
                            y1="18.5"
                            x2="15"
                            y2="18.5"
                            stroke="#AD9752"
                        />
                    </svg>
                </Button>
                <Button type="link" uri="/kontakt">
                    Mám zájem investovat
                </Button>
            </div>
        </div>
    </section>
);
export default SadSpendingBox;
