import React from "react";
import { graphql } from "gatsby";
import Breadcrumb from "../components/BreadCrumb/";
import SiteLayout from "../components/SiteLayout";
import Seo from "../components/Seo";
import Block from "../components/Block/";
import SadSpendingBox from "../components/Eshop/SadSpendingBox";
import WhyInvestWithUsGold from "../components/WhyInvestWithUsGold";
import InvestBanner from "../components/InvestBanner";
import { TYPE_GOLD } from "../constants";
import Title from "../components/Title";
import SubTitle from "../components/SubTile";
import EshopHomepage from "../components/EshopHomepage";
import Steps from "../components/Steps";

const Eshop = props => {
    const {
        data: {
            wpgraphql: { page },
            investmentGoldCategory: { nodes: investmentGoldCategory },
            investmentSilverCategory: { nodes: investmentSilverCategory },
            forColectorsCategory: { nodes: forColectorsCategory },
            presentsCategory: { nodes: presentsCategory },
        },
    } = props;

    const { title, link } = page;

    return (
        <SiteLayout location={props.location} eshop>
            <Seo
                title={`${page.seo.title || title}`}
                description={`${page.seo.metaDesc}`}
                image={page.seo.opengraphImage}
                url={link}
            />
            <Breadcrumb crumbs={[{ label: "E-shop", link: "e-shop" }]} />
            <Block leftIndent>
                <Title type={"h1"} color={"gold"} mb={30} mt={30}>
                    E-SHOP s cennými kovy
                </Title>
                <SubTitle type={"h2"} color={"gold"} mb={68}>
                    Nabízíme investiční slitky a&nbsp;sběratelské mince
                    renomovaných zahraničních mincoven i&nbsp;vlastní produkci
                    pamětních mincí.
                </SubTitle>
            </Block>

            <EshopHomepage
                investmentGoldCategory={investmentGoldCategory[0]}
                investmentSilverCategory={investmentSilverCategory[0]}
                forColectorsCategory={forColectorsCategory[0]}
                presentsCategory={presentsCategory[0]}
            />
            <Block>
                <Steps />
            </Block>

            <WhyInvestWithUsGold mb={0} />
            <InvestBanner type={TYPE_GOLD} />

            <Block grey={true}>
                <SadSpendingBox
                    images={{
                        spender: props.data.spender.childImageSharp.fixed,
                    }}
                />
            </Block>
        </SiteLayout>
    );
};

export default Eshop;

export const pageQuery = graphql`
    query GET_ESHOP_PAGE($id: ID!) {
        wpgraphql {
            page(id: $id) {
                id
                title
                seo {
                    title
                    metaDesc
                    opengraphImage
                }
                slug
            }
        }
        investmentGoldCategory: allCmCategory(
            filter: { slug: { eq: "investicni-zlato" } }
        ) {
            nodes {
                id
                name
                slug
                parent {
                    id
                    ... on CmCategory {
                        slug
                    }
                }
                image {
                    childImageSharp {
                        fixed(
                            width: 160
                            quality: 100
                            traceSVG: { color: "#B29B5B", threshold: 230 }
                        ) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                        mobileFixed: fixed(
                            width: 60
                            quality: 100
                            traceSVG: { color: "#B29B5B", threshold: 230 }
                        ) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                    }
                }
            }
        }
        investmentSilverCategory: allCmCategory(
            filter: { slug: { eq: "investicni-stribro" } }
        ) {
            nodes {
                id
                name
                slug
                parent {
                    id
                    ... on CmCategory {
                        slug
                    }
                }
                image {
                    childImageSharp {
                        fixed(
                            width: 155
                            quality: 100
                            traceSVG: { color: "#9AAAB2", threshold: 247 }
                        ) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                        mobileFixed: fixed(
                            width: 60
                            quality: 100
                            traceSVG: { color: "#9AAAB2", threshold: 247 }
                        ) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                    }
                }
            }
        }
        forColectorsCategory: allCmCategory(
            filter: { slug: { eq: "pro-sberatele" } }
        ) {
            nodes {
                id
                name
                slug
                parent {
                    id
                    ... on CmCategory {
                        slug
                    }
                }
                image {
                    childImageSharp {
                        fixed(
                            width: 260
                            quality: 100
                            traceSVG: { color: "#B29B5B", threshold: 230 }
                        ) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                        mobileFixed: fixed(
                            width: 120
                            quality: 100
                            traceSVG: { color: "#B29B5B", threshold: 230 }
                        ) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                    }
                }
            }
        }
        presentsCategory: allCmCategory(filter: { slug: { eq: "darky" } }) {
            nodes {
                id
                name
                description
                slug
                parent {
                    id
                    ... on CmCategory {
                        slug
                    }
                }
                image {
                    childImageSharp {
                        fixed(
                            width: 293
                            quality: 100
                            traceSVG: { color: "#B29B5B", threshold: 200 }
                        ) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                        mobileFixed: fixed(
                            width: 120
                            quality: 100
                            traceSVG: { color: "#B29B5B", threshold: 200 }
                        ) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                    }
                }
            }
        }
        spender: file(relativePath: { eq: "spender.png" }) {
            childImageSharp {
                fixed(width: 388, quality: 100) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
            }
        }
    }
`;
