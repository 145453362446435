import React, { Component } from "react";
import PropTypes from "prop-types";

import style from "./eshopHomepage.module.sass";
import Block from "../../components/Block/";
import CategoryCard from "../../components/CategoryCard";

class EshopHomepage extends Component {
    render = () => {
        const {
            mb,
            investmentGoldCategory,
            investmentSilverCategory,
            forColectorsCategory,
            presentsCategory,
        } = this.props;

        return (
            <section
                className={style.eshopHomepage}
                style={{ marginBottom: `${mb}px` }}
            >
                <Block>
                    <div className={style.row}>
                        <CategoryCard data={investmentGoldCategory} />
                        <CategoryCard data={investmentSilverCategory} />
                        <CategoryCard data={forColectorsCategory} />
                    </div>
                    <div className={style.row}>
                        <CategoryCard
                            data={presentsCategory}
                            wide={true}
                            data-test="category-presents"
                        />
                    </div>
                </Block>
            </section>
        );
    };
}

EshopHomepage.propTypes = {
    mb: PropTypes.number,
};

EshopHomepage.defaultProps = {
    mb: 0,
};

export default EshopHomepage;
